.iqiyi {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  margin: 3% auto;
  text-align: center;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
