.details {
  .details-summary {
    &:hover {
      cursor: pointer;
    }
  }

  i.details-icon {
    color: $global-font-secondary-color;
    @include transition(transform 0.2s ease);

    .dark & {
      color: $global-font-secondary-color-dark;
    }
  }

  .details-content {
    max-height: 0;
    overflow-y: hidden;
    @include transition(max-height 0.8s cubic-bezier(0, 1, 0, 1) -0.1s);
  }

  &.open {
    i.details-icon {
      @include transform(rotate(180deg));
    }

    .details-content {
      max-height: $MAX_LENGTH;
      @include transition(max-height 0.8s cubic-bezier(0.5, 0, 1, 0) 0s);
    }
  }
}
