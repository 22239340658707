@media only screen and (max-width: 1440px) {
  .page {
    width: 56%;
  }

  #header-desktop .header-wrapper {
    padding: 0 8vw;
  }

  .search-dropdown.desktop {
    right: 8vw;
  }
}

@media only screen and (max-width: 1200px) {
  .page {
    width: 52%;
  }

  #header-desktop .header-wrapper {
    padding: 0 4vw;
  }

  .search-dropdown.desktop {
    right: 4vw;
  }
}

@media only screen and (max-width: 960px) {
  #toc-auto {
    display: none;
  }

  #toc-static {
    display: block;
  }

  .page {
    width: 80%;
  }
}

@media only screen and (max-width: 680px) {
  #header-desktop {
    display: none;
  }

  #header-mobile {
    display: block;
  }

  body.blur {
    overflow: hidden;
  }

  .page {
    width: 100%;
    padding-top: $page-padding-top-mobile;

    .categories-card {
      .card-item {
        width: 100%;
      }
    }
  }

  .copyright {
    .copyright-line {
      .icp-splitter {
        display: none;
      }
      .icp-br {
        display: block;
      }
    }
  }
}
