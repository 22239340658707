.tag-cloud-tags {
  margin: 10px 0;

  @include link(true, true);

  a {
    display: inline-block;
    position: relative;
    margin: 5px 10px;
    overflow-wrap: break-word;
    @include transition(all ease-out 0.3s);

    &:active,
    &:focus,
    &:hover {
      @include transform(scale(1.2));
    }

    sup {
      color: $global-font-secondary-color;

      .dark & {
        color: $global-font-secondary-color-dark;
      }
    }
  }
}
