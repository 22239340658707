.admonition {
  position: relative;
  margin: .9765em 0;
  padding: 0 .75rem;
  background-color: map-get($admonition-background-color-map, 'note');
  border-left: .25rem solid map-get($admonition-color-map, 'note');
  overflow: auto;

  .admonition-title {
    margin: 0 -0.75rem;
    padding: .2rem .75rem .2rem 1.8rem;
    border-bottom: 1px solid map-get($admonition-background-color-map, 'note');
    background-color: map-get($admonition-background-color-map, 'note');
  }

  .details-summary.admonition-title:hover {
    background-color: darken(map-get($admonition-background-color-map, 'note'), 6%);
  }

  .admonition-content {
    padding: .5rem 0;
  }

  i.icon {
    font-size: 0.85rem;
    color: map-get($admonition-color-map, 'note');
    position: absolute;
    left: .6rem;
    top: .6rem;
  }

  i.details-icon {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }

  @each $type, $color in $admonition-color-map {
    &.#{$type} {
      border-left-color: $color;

      i.icon {
        color: $color;
      }
    }
  }

  @each $type, $color in $admonition-background-color-map {
    &.#{$type} {
      background-color: $color;

      .admonition-title {
        border-bottom-color: $color;
        background-color: $color;
      }

      .details-summary.admonition-title:hover {
        background-color: darken($color, 6%);
      }
    }
  }

  &:last-child {
    margin-bottom: .75rem;
  }
}
