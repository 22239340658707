@mixin link($light, $dark) {
  a {
    color: if($light, $global-link-color, $single-link-color);

    .dark & {
      color: if($dark, $global-link-color-dark, $single-link-color-dark);
    }

    &:active,
    &:hover {
      color: if($light, $global-link-hover-color, $single-link-hover-color);

      .dark & {
        color: if($dark, $global-link-hover-color-dark, $single-link-hover-color-dark);
      }
    }
  }
}
