html {
  font-family: $global-font-family;
  font-weight: $global-font-weight;
  font-display: swap;
  font-size: $global-font-size;
  line-height: $global-line-height;
  width:100%;
}

/* scrollbar, only support webkit */
::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;

  &:hover {
    background-color: $scrollbar-hover-color;
  }
}

::selection {
  background-color: $selection-color;

  .dark & {
    background-color: $selection-color-dark;
  }
}

body {
  background-color: $global-background-color;
  color: $global-font-color;
  overflow-wrap: break-word;
  scrollbar-color: auto;

  &.dark {
    color: $global-font-color-dark;
    background-color: $global-background-color-dark;
  }
}

@import "../_partial/mask";

a {
  text-decoration: none;
}

@include link(true, true);

@import "../_partial/icon";
@import "../_partial/details";
@import "../_partial/fixed-button";
