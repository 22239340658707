.friendurl {
	text-decoration: none !important;
	color: black;
}

.myfriend {
	width: 56px !important;
	height: 56px !important;
	border-radius: 50%;
	border: 1px solid #ddd;
	padding: 2px;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
	margin-top: 14px !important;
	margin-left: 14px !important;
	background-color: #fff;
}

.frienddiv {
	height: 92px;
	margin-top: 10px;
	width: 48%;
	display: inline-block !important;
	border-radius: 5px;
	background: rgba(255, 255, 255, 0.2);
	box-shadow: 4px 4px 2px 1px rgba(0, 0, 255, 0.2);
}

.frienddiv:hover {
	background: rgba(87, 142, 224, 0.15);
}

.frienddiv:hover .frienddivleft img {
	transition: 0.9s !important;
	-webkit-transition: 0.9s !important;
	-moz-transition: 0.9s !important;
	-o-transition: 0.9s !important;
	-ms-transition: 0.9s !important;
	transform: rotate(360deg) !important;
	-webkit-transform: rotate(360deg) !important;
	-moz-transform: rotate(360deg) !important;
	-o-transform: rotate(360deg) !important;
	-ms-transform: rotate(360deg) !important;
}

.frienddivleft {
	width: 92px;
	float: left;
}

.frienddivleft {
	margin-right: 2px;
}

.frienddivright {
	margin-top: 18px;
	margin-right: 18px;
}

.friendname {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.friendinfo {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

@media screen and (max-width: 600px) {
	.friendinfo {
		display: none;
	}
	.frienddivleft {
		width: 84px;
		margin: auto;
	}
	.frienddivright {
		height: 100%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.friendname {
		font-size: 14px;
	}
}
